<template>
  <BCard
    no-body
    class="p-1"
  >
    <div class="text-black text-2xl font-extrabold">
      Fulfillment Service
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        class="mt-1"
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        hover
        show-empty
      >
        <template #cell(name)="data">
          <div class="d-grid flex">
            <BAvatar :src="data.item.image_logo_url" />
            <div class="ml-1">
              <h5 class="text-black">
                {{ data.item.name }}
              </h5>
              <span class="text-gray-500">{{ data.item.city_name }}</span>
            </div>
          </div>
        </template>
        <template #cell(owner)="data">
          <div>{{ data.item.owner }}</div>
          <div>{{ data.item.phone_number }}</div>
        </template>
        <template #cell(active_date)="data">
          {{ DAY_MONTH_YEAR(data.item.active_date) }}
          <br>
          <span style="color: #828282">{{ TIME(data.item.active_date) }}</span>
        </template>
        <template #cell(availability)="data">
          <span :class="styleAvability(data.item.availability)">
            {{ data.item.availability }}
          </span>
        </template>
        <template #cell(rincian)="data">
          <span
            class="text-blue-500 font-extrabold cursor-pointer"
            @click="handleClick(data.item)"
          >
            Detail
          </span>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import { axiosIns } from '@/libs/axios'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { DAY_MONTH_YEAR, TIME } from '@/libs/filterDate'
import { fieldList } from './config'

export default {
  components: {
    BCard,
    BTable,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: fieldList,
      DAY_MONTH_YEAR,
      TIME,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.isLoading = true
      const URL = '/v1/warehouse/fulfillment'
      await axiosIns
        .get(URL)
        .then(res => {
          const { data } = res.data
          this.items = data
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: error.message,
                variant: 'danger',
              },
            },
            2000,
          )
          this.isLoading = false
        })
    },
    handleClick(data) {
      const { id } = data
      this.$router.push({
        path: `/fulfillment-service/${id}`,
      })
      secureLocalStorage.set('fullfilment', data)
    },
    styleAvability(value) {
      if (value === 'Penuh') {
        return 'full'
      }
      if (value === 'Tersedia') {
        return 'available'
      }
      return ''
    },
  },
}
</script>

<style scoped>
.full {
  color: #E31A1A;
  border: 1px solid #E31A1A;
  border-radius: 10px;
  padding: 0 10px;
  background: #FFEDED;
}

.available {
  color: #08A0F7;
  border: 1px solid #08A0F7;
  border-radius: 10px;
  padding: 0 10px;
  background: #DFF3FF;
}
</style>
